<template>
  <div class="coinrules">
    <navbarTools :leftArrow="true" :fixed="true"></navbarTools>
    <div class="content">
      <div v-for="(item, index) in rulesTxt" :key="index">
        <div class="title" v-html="item.title"></div>
        <div class="text" v-html="item.text"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import navbarTools from "@/components/navbarTools";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    const state = reactive({
      rulesTxt: [
        {
          title: `Q：我要怎么样获得工钱币呢？`,
          text: `A：入职自营岗位，前一个月每满7天，我们会赠送你1笔工钱币，一个月后，在每月的发工资日，我们会赠送你1笔工钱币，赠送金额根据你的出勤天数决定。`,
        },
        {
          title: `获得工钱币有什么条件呢？`,
          text: `A：只要入职满7天并全勤，我们就赠送工钱币。例：<br/>
                  1、法海1月1日入职，在1月7日离职，因为入职刚好满7天，所以在发放1月7日时，法海最低可领取1币价值100元的工钱币。<br/>
                  2、许仙1月1日入职，在1月6日离职，因为入职不满7天，所以许仙没有工钱币。<br/>
                  3、小青1月1日入职，目前一直在职，除了1月可以领4笔价值100元的工钱币，后面每个月都可以领取1笔价值400元的工钱币。<br/>
                  4、注：以上赠送金额为系统估算的最低金额，具体金额以实际到账金额为准`,
        },
        {
          title: `Q：工钱币是自动到我的账户吗？`,
          text: `A：不是，需要实名认证后再手动领取，系统激活后未手动领取的工钱币，将在30天后失效。`,
        },
        {
          title: `Q：工钱币存在有效期吗？`,
          text: `A：在职期间是永久有效的，离职后30天内可以正常使用，若在30天内未再次入职自营岗位，则清零余下的工钱币。`,
        },
        {
          title: `Q：关于工钱币兑换？`,
          text: `A：工钱币不可转让；若是购买了虚拟商品、非实物商品（如爱奇艺会员账号等），则不予退换。其他商品以商城规则为准！`,
        },
        {
          title: `Q：关于违规刷工钱币的惩处？`,
          text: `A：若发现有作弊行为，我们将直接取消相关资格并清零工钱币。`,
        },
      ],
    });
    return {
      ...toRefs(state),
    };
  },
});
</script>

<style lang="scss" scoped>
.coinrules {
  background: #fff;
  height: 100%;
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .content {
    position: relative;
    top: 46px;
    padding: 0px 20px;
    background: #fff;
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #ff844e;
      line-height: 28px;
      margin-top: 20px;
    }
    .text {
      font-size: 14px;
      color: #666666;
      line-height: 30px;
    }
  }
}
</style>
